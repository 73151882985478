import { createSlice } from '@reduxjs/toolkit';
// utils
import { USER_GLOBAL_HOST_API_KEY } from '../../config-global';
import { createUser } from '../../services/user/createUser';
import { createOrg } from '../../services/register/createOrg';
import { addUserToOrg } from '../../services/register/addUserToOrg';
import axios from '../../utils/axios';
import { updateUser } from '../../services/user/updateUser';
import { updateOrgApi } from '../../services/register/updateOrgApi';

const initialState = {
  isLoading: false,
  error: null,
  customers: null, // customers === orgs from api
  initialized: false,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMERS SUCCESS
    getCustomersSuccess(state, action) {
      const orgs = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.customers = orgs;
    },
  },
});

// Actions
export const { getCustomersSuccess } = customersSlice.actions;

// Reducer
export default customersSlice.reducer;

export const getAllCustomers = (state) => state.customers;

export function getCustomers(page, pageSize, search) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      // const response = await axios.get('/lms_user/api/org');
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/all_org`,
        params: {
          page,
          pageSize,
          search,
        },
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(customersSlice.actions.hasError(error));
    }
  };
}

export function changeStatus(org_id) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      const response = await axios.get(`/lms_user/api/org_status/${org_id}`);
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(customersSlice.actions.hasError(error));
    }
  };
}

export function addCustomer(data) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      // creating user
      const userFormData = new FormData();
      userFormData.append('full_name', data?.userName);
      userFormData.append('email', data.email);
      userFormData.append('country_code', data.country);
      userFormData.append('phone', data.phoneNumber);
      userFormData.append('password', data.password);

      const user = await createUser(userFormData);

      // creating org
      const orgFormData = new FormData();
      orgFormData.append('name', data.name);
      orgFormData.append('email', data.email);
      orgFormData.append('phone', data.phoneNumber);
      orgFormData.append('country_code', data.country);
      orgFormData.append(
        'partner',
        JSON.parse(localStorage.getItem('profile'))?.reseller_details?.mongo_resellerid
      );

      const org = await createOrg(orgFormData, user._id);

      // adding user to org
      const obj = {
        user: user._id,
        partner: JSON.parse(localStorage.getItem('profile'))?.reseller_details?.mongo_resellerid,
        role: 'admin',
      };
      const rel = await addUserToOrg(obj, org._id);

      const formData = new FormData();
      formData.append('org_name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', `${data?.country}${data?.phoneNumber}`);
      formData.append('password', data?.password);
      formData.append('location', data?.location);
      formData.append('about', data?.about);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org`,
        data: formData,
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}

export function updateCustomer(data, currentUser) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      // updating user
      const userFormData = new FormData();
      userFormData.append('full_name', data?.userName);
      userFormData.append('email', data.email);
      userFormData.append('country_code', data.country);
      userFormData.append('phone', data.phoneNumber);

      const user = await updateUser(userFormData, currentUser?.user?.mongo_userid);

      // updating org
      const orgFormData = new FormData();
      orgFormData.append('name', data.name);
      orgFormData.append('email', data.email);
      orgFormData.append('phone', data.phoneNumber);
      orgFormData.append('country_code', data.country);

      const org = await updateOrgApi(orgFormData, currentUser?.mongo_org_id);

      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('org_name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('about', data?.about);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org/${currentUser?.org_id}`,
        data: formData,
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}

export function resetPassword(password, userId) {
  return async () => {
    const formData = new FormData();
    formData.append('password', password);
    try {
      await axios({
        method: 'put',
        url: `${USER_GLOBAL_HOST_API_KEY}/users/${userId}`,
        data: formData,
      });
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}

export function manageAccount(data) {
  return async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/get-access-token`,
        params: {
          user: data?.userId,
          organization: data?.orgId,
        },
      });
      const { accessToken } = response.data;

      return { accessToken };
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}
// export function manageAccount(data) {
//   return async () => {
//     try {
//       const response = await axios({
//         method: 'post',
//         url: `/lms_user/api/direct_manage_account`,
//         params: {
//           user_type: data?.userType,
//           org_id: data?.orgId,
//           user_id: data?.userId,
//         },
//       });
//       const { access_token, refresh_token } = response.data;
//       return { access_token, refresh_token };
//     } catch (error) {
//       throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
//     }
//   };
// }

export function updateAccManager(data) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/account_manager`,
        params: {
          org_id: data?.orgId,
          users: data?.users,
          primary: data?.primaryAm,
        },
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
      return response.data?.find((org) => org?.org_id === data?.orgId);
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function transferToPartner(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/transfer_org`,
        params: {
          org_id: data?.orgID,
          reseller_id: data?.partnerID,
        },
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function moveOrgToBin(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'delete',
        url: `/lms_user/api/org/${data?.orgID}`,
      });
      // console.log(response.data);
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function restoreOrgFromBin(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org/${data?.orgID}/restore`,
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
