import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import { countries } from '../../assets/data';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  label,
  type,
  helperText,
  placeholder,
  hideNone,
  ...other
}) {
  const { control } = useFormContext();

  const formatCountryValue = (country) => {
    if (!country || !country.label || !country.phone) return '';
    return `${country.label}-+${country.phone}`;
  };
  const formatOnlyCountryCode = (country) => {
    if (!country || !country.phone) return '';
    return `+${country.phone}`;
  };

  const parseCountryValue = (value) => {
    if (!value) return {};
    const pcv = value.split('-+');
    return { label: pcv.label, phone: `+${pcv.phone}` };
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...fieldProps } = field;
        const parsedValue = parseCountryValue(value);
        const selectedCountry = getCountry(parsedValue.label);

        if (type === 'country') {
          return (
            <Autocomplete
              {...fieldProps}
              id={`autocomplete-${name}`}
              options={hideNone ? countries.filter((c) => c.label !== 'None') : countries}
              autoHighlight
              getOptionLabel={(option) => option.label || ''}
              value={selectedCountry.label ? selectedCountry : null}
              onChange={(event, newValue) => {
                const formattedValue = formatCountryValue(newValue);
                onChange(formattedValue);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.label}>
                  <Iconify icon={`circle-flags:${option.code?.toLowerCase()}`} sx={{ mr: 1 }} />
                  {option.label === 'None'
                    ? option.label
                    : `${option.label} (${option.code}) +${option.phone}`}
                </li>
              )}
              renderInput={(params) => {
                const country = selectedCountry;

                return (
                  <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ display: country.code ? 'flex' : 'none' }}
                        >
                          <Iconify
                            icon={`circle-flags:${country.code?.toLowerCase()}`}
                            sx={{ mr: -0.5, ml: 0.5 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                );
              }}
              {...other}
            />
          );
        }

        if (type === 'onlyCountryCode') {
          return (
            <Autocomplete
              {...fieldProps}
              id={`autocomplete-${name}`}
              options={hideNone ? countries.filter((c) => c.label !== 'None') : countries}
              autoHighlight
              getOptionLabel={(option) => option.label || ''}
              value={selectedCountry.label ? selectedCountry : null}
              onChange={(event, newValue) => {
                const formattedValue = formatOnlyCountryCode(newValue);
                onChange(formattedValue);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.label}>
                  <Iconify icon={`circle-flags:${option.code?.toLowerCase()}`} sx={{ mr: 1 }} />
                  {option.label === 'None' ? option.label : `+${option.phone}`}
                </li>
              )}
              renderInput={(params) => {
                const country = selectedCountry;

                return (
                  <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ display: country.code ? 'flex' : 'none' }}
                        >
                          <Iconify
                            icon={`circle-flags:${country.code?.toLowerCase()}`}
                            sx={{ mr: -0.5, ml: 0.5 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                );
              }}
              {...other}
            />
          );
        }

        return (
          <Autocomplete
            {...fieldProps}
            id={`autocomplete-${name}`}
            options={countries}
            getOptionLabel={(option) => option.label || ''}
            value={selectedCountry.label ? selectedCountry : null}
            onChange={(event, newValue) => {
              const formattedValue = formatCountryValue(newValue);
              onChange(formattedValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
            {...other}
          />
        );
      }}
    />
  );
}

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
  hideNone: PropTypes.bool,
};

// ----------------------------------------------------------------------

export function getCountry(inputValue) {
  const option = countries.find((country) => country.label === inputValue) || {};
  return { ...option };
}
