// utils
import axios from '../../utils/axios';
// import axios from 'axios';
import { USER_GLOBAL_HOST_API_KEY } from '../../config-global';

const createPartner = async (data) => {
  try {
    // const userId = localStorage.getItem('_id');
    const response = await axios({
      method: 'post',
      url: `${USER_GLOBAL_HOST_API_KEY}/partners`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { createPartner };
