// utils
import axios from '../../utils/axios';
// import axios from 'axios';
import { USER_GLOBAL_HOST_API_KEY } from '../../config-global';

const addUserToOrg = async (data, orgId) => {
  try {
    // const userId = localStorage.getItem('_id');
    const response = await axios({
      method: 'put',
      url: `${USER_GLOBAL_HOST_API_KEY}/organizations/${orgId}/user`,
      data,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { addUserToOrg };
