import GoogleIcon from '@mui/icons-material/Google';
import { useEffect, useState, Children } from 'react';

import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  users: icon('users'),
  whatsApp: icon('whatsApp'),
  partners: icon('partners'),
  accountSetup: icon('accountSetup'),
  reports: icon('reports'),
  whitelable: icon('whitelable-setup'),
  profile: icon('profile'),
};

const whatsappDemoUrl = new URL(window.location.href);

const useNavConfig = () => {
  const [navConfig, setNavConfig] = useState([]);

  useEffect(() => {
    const profile = localStorage.getItem('profile');
    const reseller_type = JSON.parse(profile)?.reseller_details?.reseller_type;
    const navArr = [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        // subheader: 'general v4.1.0',
        items: [
          { title: 'Customers', path: PATH_DASHBOARD.customers.root, icon: ICONS.users },
          (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
            title: 'Partners',
            path: PATH_DASHBOARD.partners.root,
            icon: ICONS.partners,
          },
          (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
            title: 'Google Workspace',
            path: PATH_DASHBOARD.googleWorkspace.root,
            icon: <GoogleIcon />,
            children: [
              {
                title: 'Customers',
                path: PATH_DASHBOARD.googleWorkspace.customers,
              },
              {
                title: 'Transactions',
                path: PATH_DASHBOARD.googleWorkspace.transactions,
              },
            ],
          },
          {
            title: 'WhatsApp',
            path: PATH_DASHBOARD.whatsApp.root,
            icon: ICONS.whatsApp,
            children: [
              (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
                title: 'Partners',
                path: PATH_DASHBOARD.whatsApp.partners,
              },
              {
                title: 'Customers',
                path: PATH_DASHBOARD.whatsApp.customers,
              },
              (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
                title: 'Transactions',
                path: PATH_DASHBOARD.whatsApp.transactions,
              },
              (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
                title: 'Templates',
                path: PATH_DASHBOARD.whatsApp.templates,
              },
              {
                title: 'Start Demo',
                path: `${whatsappDemoUrl.origin}/whatsapp/demo`,
              },
              (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
                title: 'Customize Demo',
                path: PATH_DASHBOARD.whatsApp.customizeDemo,
              },
              {
                title: 'Fix Display Name',
                path: PATH_DASHBOARD.whatsApp.fixDisplayName,
              },
              {
                title: 'Billing',
                path: PATH_DASHBOARD.whatsApp.billing,
                children: [
                  {
                    title: 'Billing Profile',
                    path: PATH_DASHBOARD.whatsApp.billingProfile,
                  },
                  {
                    title: 'Billing Payment',
                    path: PATH_DASHBOARD.whatsApp.billingpayment,
                  },
                ],
              },
            ],
          },
          (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
            title: 'Reports',
            path: PATH_DASHBOARD.reports,
            icon: ICONS.reports,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
            title: 'users',
            path: PATH_DASHBOARD.users.root,
            icon: ICONS.users,
            // children: [
            //   { title: 'Four', path: PATH_DASHBOARD.user.four },
            //   { title: 'Five', path: PATH_DASHBOARD.user.five },
            //   { title: 'Six', path: PATH_DASHBOARD.user.six },
            // ],
          },
          // {
          //   title: 'Partner Profile',
          //   path: PATH_DASHBOARD.partnersProfile.root,
          //   icon: ICONS.profile,
          //   children: [
          //     {
          //       title: 'Profile',
          //       path: PATH_DASHBOARD.partnersProfile.partnerProfile,
          //     },
          //     {
          //       title: 'Billing Profile',
          //       path: PATH_DASHBOARD.partnersProfile.billingProfile,
          //     },
          //   ],
          // },
          {
            title: 'Partner Profile',
            path: PATH_DASHBOARD.partners.profile,
            icon: ICONS.profile,
          },
          (reseller_type === 'whitelabel' || reseller_type === 'whitelable') && {
            title: 'whitelabel',
            path: PATH_DASHBOARD.whitelableConfig,
            icon: ICONS.whitelable,
          },
          // {
          //   title: 'Lead Setup',
          //   path: PATH_DASHBOARD.leadsetup,
          //   icon: <LeaderboardOutlinedIcon />,
          // },
        ],
      },
    ];

    setNavConfig(navArr);
  }, []);

  return navConfig;
};

export default useNavConfig;
